import store from '@/store'

export const eventsStore = {
    fields: [{
        name: "id",
        dataSource: "Id"
      },
      {
        name: "name",
        dataSource: "Name"
      },
      {
        name: "startDate",
        dataSource: "StartDate",
        type: "date"
      },
      {
        name: "endDate",
        dataSource: "EndDate",
        type: "date"
      },
      {
        name: "PlanningLineId",
        dataSource: "PlanningLineId",
        // convert   : (v) => Number(v),
      },
      // {
      //   name: 'cls',
      //   dataSource: 'Cls'
      // },
      {
        name: 'eventTypeId',
        dataSource: 'EventTypeId'
      },
      {
        name: 'affairId',
        dataSource: 'AffairId'
      },
      {
        name: 'companyId',
        dataSource: 'CompanyId'
      },
      {
        name: 'style',
        dataSource: 'Cls'
      },
      // {
      //   name: "draggable",
      //   dataSource: "Draggable",
      // },
      // {
      //   name: "resizable",
      //   dataSource: "Resizable",

      // }
    ],
}

export const resourcesStore = {
  fields: [{
      name: "id",
      dataSource: "Id",

    },
    {
      name: "name",
      dataSource: "Name"
    },
    {
      name: "subName",
      dataSource: "SubName"
    },
    {
      name: "group",
      dataSource: "Group"
    },
  ],
};
export const assignmentStore = {
  fields: [{
      name: "id",
      dataSource: "Id",

    },
    {
      name: "resourceId",
      dataSource: "ResourceId"
    },
    {
      name: "eventId",
      dataSource: "EventId"
    },
  ],
}
console.log("store > ", store.getters.publicHolidayList)
export const timeRangeStore = {
  data : store.getters.publicHolidayList
//    [{
//     id             : 1,
//     startDate      : '2019-01-01T11:00',
//     endDate        : '2019-01-10T13:00',
//     name           : 'Lunch',
//     // this time range should repeat every day
//     recurrenceRule : 'FREQ=DAILY'
// }]
}