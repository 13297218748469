
import { StringHelper, DateHelper, Scheduler } from "@bryntum/schedulerpro";
const { xss } = StringHelper
import {
  columns
} from "./columns.js"
import {
  viewPreset
} from "./viewPreset.js"

import {
  project
} from "./project.js"
import {
  listeners
} from "./listeners.js"
import {
  toolBar
} from "./toolBar.js"
import {
  features
} from "./features.js";


const schedulerConfig = {
  useInitialAnimation: "slide-from-left",
  eventColor: "#0b3571",
  flex     : '1 0 100%',

  startDate: new Date(2022, 3, 1),
  endDate: new Date(2022, 6, 1),

  rowHeight: 50,
  barMargin: 5,
  weekStartDay: 1,
  /* Setting the default values for the calendar. */
  snap: true,
  multiEventSelect: true,
  passStartEndParameters: true, // retour de l'api envoie une date de début ET une date de fin de l'evenement

  /* FONCTIONALITES  */
  features: features,
  /* DATA API STORE ETC  */
  project: project,
  /* BARRE D'OUTILS */
  tbar: toolBar,
  /* PARAMETRES D'AFFICHAGE DU PLANNING (TEMPORALITE ETC...) */
  viewPreset: viewPreset,
  /* PARAMETRES D'AFFICHAGE DES COLLONNES DE GAUCHE (Nom des collabs etc...) */
  columns: columns,
  
  listeners : listeners,

  eventRenderer({ eventRecord, resourceRecord, renderData }) {
    // renderData.style = 'color:white';                 // You can use inline styles too.

    // Property names with truthy values are added to the resulting elements CSS class.
    // renderData.cls.isImportant = this.isImportant(eventRecord);
    // renderData.cls.isModified = eventRecord.isModified;

    // // Remove a class name by setting the property to false
    // renderData.cls[scheduler.generatedIdCls] = false;

    // Or, you can treat it as a string, but this is less efficient, especially
    // if your renderer wants to *remove* classes that may be there.
    // renderData.cls += ' extra-class';

    // return StringHelper.xss`${DateHelper.format(eventRecord.startDate, 'YYYY-MM-DD')}: ${eventRecord.name}`;
    return StringHelper.xss`<div class="bryntumEvent"><div class="bryntumEventTitle">${eventRecord.name}</div><div class="bryntumEventSubTitle">Sous-titre</div>`;
  }
}

export async function init() {
  return new Promise((resolve, reject) => {
    resolve(schedulerConfig)
  })
}
