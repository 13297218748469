import {Scheduler} from "@/views/Bryntum.vue"
import {DomClassList} from "@bryntum/schedulerpro"

export let toolBar = {
  title   : 'A Test Panel',
  items:[
    
    {
      type: 'displayField',
      label:"Planning",
    },
    '->',
    {
      type: 'textfield',
      ref: 'filterByName',
      icon: 'b-fa b-fa-filter',
      placeholder: 'Filtrer par nom',
      clearable: true,
      keyStrokeChangeDelay: 100,
      triggers: {
        filter: {
          align: 'start',
          cls: 'b-fa b-fa-filter'
        }
      },
      onChange({
        value
      }) {
       
        value = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Replace all previous filters and set a new filter
        Scheduler.instance.eventStore.filter({
          filters: event => event.name.match(new RegExp(value, 'i')),
          replace: true
        });

      }
    },
    // {
    //   type: 'textfield',
    //   ref: 'highlight',
    //   placeholder: 'Mettre en évidance',
    //   clearable: true,
    //   keyStrokeChangeDelay: 100,
    //   triggers: {
    //     filter: {
    //       align: 'start',
    //       cls: 'b-fa b-fa-search'
    //     }
    //   },
    //   onChange({
    //     value
    //   }) {
    //     Scheduler.instance.eventStore.forEach(task => {
    //       const taskClassList = new DomClassList(task.cls);

    //       if (value !== '' && task.name.toLowerCase().includes(value.toLowerCase())) {
    //         taskClassList.add('b-match');
    //       } else if (value !== '' && !task.name.toLowerCase().includes(value.toLowerCase())) {
    //         taskClassList.add('no-b-match');

    //       } else {
    //         taskClassList.remove('b-match');
    //         taskClassList.remove('no-b-match');
    //       }

    //       task.cls = taskClassList.value;
    //     });

    //     Scheduler.instance.element.classList[value.length > 0 ? 'add' : 'remove']('b-highlighting');
    //   }
    // }
  ]
}