import store from '@/store'
import {
  Scheduler
} from '@/views/Bryntum.vue'
export const listeners = {
  beforeCopy(event) {
    console.log('beforeCopy', event)
  },
  beforePaste({
    source,
    records,
    date,
    resourceRecord,
    isCut
  }) {
    if (isCut) {
      return true
    } else {
      records.forEach(record => {

        console.debug(records, date, resourceRecord, isCut);
        console.log('is Cut ? ', isCut)
        const selectedFacilities = [resourceRecord.id];
        const selectedStaff = record.assignments.filter(x => x.id.startsWith("ES_")).map(x => x.resource.id);
        const selectedResourceIds = [...selectedFacilities, ...selectedStaff];


        var newRecords = Scheduler.instance.eventStore.add({
          Cls: record.get('Cls'),
          EndDate: record.get('EndDate'),
          EventTypeId: record.get('EventTypeId'),
          Name: record.get('Name'),
          StartDate: date,
          allDay: record.get('allDay'),
          constraintDate: record.get('constraintDate'),
          constraintType: record.get('constraintType'),
          direction: record.get('direction'),
          duration: record.get('duration'),
          durationUnit: record.get('durationUnit'),
          exceptionDates: record.get('exceptionDates'),
          inactive: record.get('inactive'),
          manuallyScheduled: record.get('manuallyScheduled'),
          percentDone: record.get('percentDone'),
          postamble: record.get('postamble'),
          preamble: record.get('preamble'),
        });

        newRecords[0].assign(selectedResourceIds);
        return false;

      })
    }
    return false

  },
  beforeTaskEditShow({
    taskEdit,
    taskRecord,
    editor
  }) {
    console.log(">>beforeTaskEditShow")
    const generalTab = editor.widgetMap.generalTab;
    const eventTypeIdField = editor.widgetMap.eventTypeId;
    const companiesListField = editor.widgetMap.companiesList;
    const affairsListField = editor.widgetMap.affairsList;


    eventTypeIdField.items = store.getters.eventTypesList.map(el => ({
      text: el.label,
      value: el.id,
      color: el.color,
      data: el
    }));
    //! Attention <!> Cette ligne devra être résolu, elle sert de réassociation de l'eventId lors de l'update d'un event 
    // eventTypeIdField.value = store.getters.eventTypesList.find(el=>el.id==taskRecord.eventTypeId).id;
    companiesListField.items = store.getters.companiesList.map(el => ({
      text: el.name,
      value: el.id,
      color: "#0c3571",
      data: el
    }));
    affairsListField.items = store.getters.affairsList.map(el => ({
      text: el.name,
      value: el.id,
      color: "#0c3571",
      data: el
    }));
    return true
  },
  // beforeEventEdit(eventEdit, { eventRecord, resourceRecord, eventElement }) {
  //     console.log('eventEdit data ', eventEdit)
  //     return false
  // }
}
