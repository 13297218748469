const columns = [{
  text : "Collaborateur",
  field: "name",
  width: 150
},
{
  text : "Groupe",
  field: "group",
  width: 150
}]
export {columns}
