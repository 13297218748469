
import * as BryntumStore from "./BryntumStore.js"
import {Scheduler} from "@/views/Bryntum.vue"
import store from "@/store"
import {
    vm
  } from "@/main"

  let lastSync={
    pack:""
  }; 
  
export let project = {
    calendar: "weekends",
    calendarsData: [
      {
          id        : 'general',
          name      : '24 hour calendar',
          intervals : [
              {
                  recurrentStartDate : 'on Sat at 0:00',
                  recurrentEndDate   : 'on Mon at 0:00',
                  isWorking          : false
              }
          ],
          expanded : true,
          children : [
              {
                  id        : 'business',
                  name      : 'Business hours  (8am - 5pm)',
                  intervals : [
                      {
                          recurrentStartDate : 'every weekday at 12:00',
                          recurrentEndDate   : 'every weekday at 13:00',
                          isWorking          : false
                      },
                      {
                          recurrentStartDate : 'every weekday at 17:00',
                          recurrentEndDate   : 'every weekday at 08:00',
                          isWorking          : false
                      }
                  ]
              },
              {
                  id        : 'nightshift',
                  name      : '7h par nuit',
                  intervals : [
                      {
                          recurrentStartDate : 'every weekday at 6:00',
                          recurrentEndDate   : 'every weekday at 22:00',
                          isWorking          : false
                      }
                  ]
              }
          ]
      }
    ],
    timeRangeStore:BryntumStore.timeRangeStore,

    //Définition des datas
    resourceStore: BryntumStore.resourcesStore,
    eventStore: BryntumStore.eventsStore,
    assignmentStore: BryntumStore.assignmentStore,

    /* Requêtes au backend */
    autoLoad: true,
    autoSync: true,
    autoSyncTimeout: 500,
    validateResponse: true,
    transport: {
      load: {
        url: `${process.env.VUE_APP_API_ORISIS}Plannings/schedulercrud/load`,
        paramName: "q",
        credentials: 'omit',
        headers: {
          Authorization: `Bearer ${store.getters.userInfo.token}`
        },

        params: {
          planningViewId: vm.$route.params.id,
          startDate: '2022-01-01T00:00:00Z',
          endDate: '2023-03-02T00:00:00Z',
        },
      },
      sync: {
        url: `${process.env.VUE_APP_API_ORISIS}Plannings/schedulercrud/sync`,
        credentials: 'omit',
        headers: {
          Authorization: `Bearer ${store.getters.userInfo.token}`
        },
      },
  

    },
    

    /*  */
    onBeforeSync: (response) => {
      console.log("onBeforeSync", response);
      delete response.pack.revision
      
      //console.log(JSON.stringify(lastSync.pack.events) == JSON.stringify(response.pack.events) )
      // Si la requête demandée est identique alors on stop la synchronication
      if(JSON.stringify(lastSync.pack.events) == JSON.stringify(response.pack.events) && JSON.stringify(lastSync.pack.assignments) == JSON.stringify(response.pack.assignments)){
        console.log("onBeforeSyncSTOP");
        lastSync = response;
        return false;
      }
      lastSync = response;

      // return false;

      
    },

    onHasChanges: (response) => {
      // console.log("Scheduler",Scheduler.instance.project.sync())
      /* Calling the sync method of the scheduler. */
      // Scheduler.instance.project.sync()
      //console.log("response", response);
      // Scheduler.instance.project.clearChanges();
      
      // Scheduler.instance.project.cancelRequest()
    },
  
    onRequestFail: (event) => {
      const {
        requestType,
        response
      } = event,
      serverMessage = response && response.message,
        exceptionText = `Action "${requestType}" failed. ${
		  serverMessage ? ` Server response : ${serverMessage}`: ""
		}`;

      // Toast.show({
      //   html: exceptionText,
      //   color: "b-red",
      //   style: "color:white",
      //   timeout: 10000,
      // });

      console.error(exceptionText);
    },

  };