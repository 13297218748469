<template>
  <div class="page-container">
    <bryntum-scheduler-pro
      ref="scheduler"
      v-bind="schedulerConfig"
      v-if="loaded"
    />
    <!-- <AppCustomizer v-if="scheduler" :schedulerpro="scheduler" /> -->
  </div>
</template>

<script>
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-vue'
import { init } from '@/schedulerConfig/AppConfig.js'
let Scheduler = null

let Bryntum = {
  name: 'app',
  components: {
    BryntumSchedulerPro
  },
  data () {
    return {
      schedulerConfig: null,
      showEditor: false,
      eventRecord: null,
      eventStore: null,
      resourceId: null,
      assignmentStore: null,
      scheduler: undefined,
      loaded: false
    }
  },
  async mounted () {
    await this.$store.dispatch('fetchEventTypesList').then(async () => {
      await this.$store.dispatch('fetchCompaniesList').then(async () => {
        await this.$store.dispatch('fetchAffairsList').then(() => {
          init()
            .then(config => {
              console.log(this.$store)
              this.schedulerConfig = config
              this.$nextTick(() => {
                this.scheduler = this.$refs['scheduler']
                this.schedulerConfig.project.transport.load.params.planningViewId = this.$route.params.id
                this.loaded = true
                this.$nextTick(() => {
                  console.log('maconfigmonte', this.$refs['scheduler'])
                  Scheduler = this.$refs['scheduler']
                })
              })
            })
            .catch(err => {})
        })
      })
    })
  },

  methods: {
    setEventStyle (style) {
      const eventStore = this.scheduler.instance.eventStore

      eventStore.forEach(eventRecord => {
        if (!this.storedOriginalStyles) {
          eventRecord.originalStyle = eventRecord.eventStyle
        }

        if (style === 'mixed') {
          eventRecord.eventStyle = eventRecord.originalStyle
        } else {
          eventRecord.eventStyle = style
        }
      })
      this.storedOriginalStyles = true
    }
  }
}
export { Scheduler }
export default Bryntum
</script>

<style lang="scss" scoped>
@import '../App.scss';
</style>

<style>
.b-match {
  opacity: 1 !important;
}
.no-b-match {
  opacity: 0.2 !important;
}
.page-container {
  height: calc(100vh - 160px) !important;
  width: 100%;
}
.b-textareafield input{
  min-height: 300px
}
</style>
