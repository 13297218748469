import store from '@/store'
import {
  StringHelper
} from "@bryntum/schedulerpro";
import {
  Scheduler
} from "@/views/Bryntum.vue"
const {
  xss
} = StringHelper
export let features = {
  dependencies: false,
  scheduleContext: true,
  eventCopyPaste: true,

  filterBar: true,
  resourceNonWorkingTime: true,

  taskEdit: {
    items: {
      generalTab: {
        weight: 0,
        order: 0,
        position: 0,
        title: "Général",

        items: {
          percentDoneField: false,
          eventTypeId: {
            ref: "eventTypeId",
            name: "eventTypeId",
            label: "Type",
            type: 'combo',
            required: true,
            items: [],
            listItemTpl: item => xss `<div class="pin-color" style="background-color:${item.color} !important"></div><div>${item.text}</div>`,
            hidden: false,
            picker: {},
            readOnly: false,
            value: '',
            onChange({
              source,
              value
            }) {
              if (value && value !== "") {
                const dataTypeEvent = store.getters.eventTypesList.find(el => el.id == value)
                if (dataTypeEvent.color && dataTypeEvent.color !== "") {
                  source.parent.items.find(el => el.ref == "styleField").value = 'background-color:' + dataTypeEvent.color + ' !important';
                }

                if (dataTypeEvent.isDependent) {
                  source.parent.items.find(el => el.ref == "companiesList").hidden = false
                } else {
                  source.parent.items.find(el => el.ref == "companiesList").hidden = true
                  source.parent.items.find(el => el.ref == "affairsList").hidden = true
                }
              }
            }
          },
          companiesList: {
            ref: "companiesList",
            label: "Entreprise",
            type: 'combo',
            required: true,
            items: [],
            listItemTpl: item => xss `<div class="pin-color" style="background-color:${item.color} !important"></div><div>${item.text}</div>`,
            hidden: true,
            picker: {},
            readOnly: false,
            onChange({
              source
            }) {
              if (source.record.data.color && source.record.data.color !== "") {
                source.parent.items.find(el => el.ref == "styleField").value = 'background-color:' + source.record.data.color + ' !important';
              }
              source.parent.items.find(el => el.ref == "affairsList").hidden = false
            }
          },
          affairsList: {
            ref: "affairsList",
            label: "Affaire",
            type: 'combo',
            required: true,
            items: [],
            listItemTpl: item => xss `<div class="pin-color" style="background-color:${item.color} !important"></div><div>${item.text}</div>`,
            hidden: true,
            picker: {},
            readOnly: false
          },
          // Champs masqué qui porte la proriété style que nous générons via le type d'event ou l'entreprise
          styleField: {
            ref: "styleField",
            type: 'textfield',
            label: 'Style',
            hidden: true,
            name: 'style'
          },
          durationUnitField: {
            type: 'textfield',
            name: 'durationUnit',
            defaultValue: 'hour',
            value: 'hour',
            hidden: true
          }
        }
      },
      notificationTab: {
        title: "Notifications",
        items: {
          // phoneField: {
          //   ref: "phoneField",
          //   label: "Téléphone",
          //   type: 'textfield',
          //   required: false,
          //   name: 'phone',
          //   hidden: false
          // },
          smsField: {
            ref: "smsField",
            label: "Contenu du sms",
            type: 'textareafield',
            required: false,
            name: 'sms',
            hidden: false
          },
          sendSms: {
            ref: "sendSmsButton",
            text: "Envoyer",
            type: 'button',
            required: false,
            name: 'sendSms',
            hidden: false,
            onClick: (click) => {
              //check if id is valid guid
              console.log('click.source',click)
              const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
                //tableau d'id des planningLine en rapport avec cet event
                // let resourcesIdsOfEvent = Scheduler.instance._project.assignmentStore._data.filter(el => el.EventId == click.source._parent.initialConfig.parent._parent.eventEditFeature.record._data.Id).map(el => el.ResourceId);
                //tableau des ids des collaborateurs des planningLineId de resourcesIdsOfEvent
                let collaboratorIds = Scheduler.instance._project.resourceStore._data.filter(el => click.source._parent._parent._widgetMap.resourcesField._lastValue.includes(el.Id)).map(el => el.CollaboratorId);
                store.dispatch('sendSms', {
                  collaboratorIds: collaboratorIds,
                  text: click.source._parent._widgetMap.smsField.value,
                  sendAt: new Date().toISOString(),
                  withResponse: false
                })


            }
          },
        },
        weight: 1,
      },
      advancedTab: {
        title: "Paramètres",
        weight: 2
      },
      predecessorsTab: false,
      successorsTab: false,
      notesTab: false
    },
    editorConfig: {
      title: 'Edition d\'un évènement',
      bbar: {

        layout: {
          justify: 'space-between',
        },
        items: {
          saveButton: {
            type: 'button',
            text: 'Enregistrer',
            icon: 'b-fa b-fa-plus',
            cls: 'btn float-left btn-primary text-white white',
            weight: 0,
          },
          cancelButton: null,
          deleteButton: {
            type: 'button',
            text: '',
            icon: 'b-fa b-fa-trash',
            cls: 'btn float-right btn-outline-danger danger',
            alignSelf: 'strech',
            weight: 2,
          }
        }
      }
    }
  },
  stripe: true,
  timeRanges: false,


};

// multiSelect : true,
// dragConfig  : { enableCopy : true },
// timeRanges : {
//     enableResizing      : true,
//     showCurrentTimeLine : true,
//     showHeaderElements  : true,
//     tooltipTemplate     : ({ timeRange }) => timeRange.name
// },
// resourceTimeRangesFeature: true,
// resourceTimeRanges: [
//   {
//     id: 7,
//     resourceId: 21,
//     startDate: "2022-04-26",
//     endDate: "2022-04-27",
//     name: "AFK (uses timeRangeColor)",
//     cls: "red"
//   }
// ]
// let sendSmsButton = new Button({
//   appendTo : 'first',
//   badge    : 3,
//   text     : 'Showing badge',
//   color    : 'b-blue',
//   onClick
// });
